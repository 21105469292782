export const russianTranslation = {
    "Website Logo": "Лого вебсайта",
    "Featured": "Избранное",
    "Sign In": "Войти",
    "Daily rent from owners all over Belarus": "Посуточная аренда от владельцев по всей Беларуси",
    "You can rent apartment": "На сайте XXX вы можете снять квартиру на сутки для работы и отдыха, снять дом и усадьбу для загородного отдыха, находите у нас самые выгодные предложения посуточной аренды квартир и усадеб без посредников",
    "Gomel": "Гомель",
    "Brest": "Брест",
    "Grodno": "Гродно",
    "Pinsk": "Пинск",
    "Minsk": "Минск",
    "Mogilev": "Могилёв",
    "Find": "Найти",
    "Apartments": "Квартиры",
    "Houses": "Усадьбы",
    "Guests": "Гости",
    "guest_zero":"{{count}} гостей",
    "guest_one":"{{count}} гость",
    "guest_few":"{{count}} гостя",
    "guest_many":"{{count}} гостей",
    "Filters" : "Фильтры",
    "From": "от",
    "To": "до",
    "Price": "Стоимость",
    "Area": "Площадь",
    "Amenities": "Удобства",
    "Nothing Found": "Ничего не найдено",
    "Remove Filters": "Сбросить все фильтры",
    "Price Low To High": "Сначала дешевле",
    "Price High To Low": "Сначала дороже",
    "Popularity Low To High": "Сначала популярнее",
    "Popularity High To Low": "Сначала не популярнее",
    "Show Contacts": "Показать контакты",
    "Rooms": "{{count}}-комн.",
    "Create Apartment":"Добавить квартиру",
    "Title": "Название",
    "Apartment Description": "Описание квартиры",
    "Rooms Quantity": "Количество комнат",
    "Beds Quantity": "Количество кроватей",
    "Quests Quantity": "Количество гостей",
    "Currency": "Валюта",
    "Address":"Адрес",
    "Photos":"Фотографии",
    "Add": "Добавить",
    "Save":"Сохранить",
    "Share": "Поделится",
    "Day": "сутки",
    "Leave Request": "Сделать заявку",
    "Book Apartment": "Забронировать квартиру",
    "Enter Your Name": "Введите ваше имя",
    "Name": "Имя",
    "Phone":"Телефон",
    "Email":"E-mail",
    "Number Of People": "Количество человек",
    "Check-in Date": "Дата заезда",
    "Check-out Date": "Дата отъезда",
    "Send Request": "Отправить Заявку",
    "Additional Information": "Дополнительно",
    "Room_zero":"Комнат",
    "Room_one":"Комната",
    "Room_few":"Комнаты",
    "Room_many":"Комнат",
    "Bed_zero":"Кроватей",
    "Bed_one":"Кровать",
    "Bed_few":"Кровати",
    "Bed_many":"Кроватей",
    "Guest_zero":"Гостей",
    "Guest_one":"Гость",
    "Guest_few":"Гостя",
    "Guest_many":"Гостей",
    "On Map": "На карте", //TODO rename to On The map, and in the future always use articles it translations keys
    "Description": "Описание",
    "Rules Of Residence": "Правила проживания",
    "Near The House": "Рядом с жилищем",
    "Reviews": "Отзывы",
    "House Description": "Описание дома",
    "In The Kitchen": "На кухне",
    "In The Bathroom": "В ванной",
    "Entertainment And Multimedia": "Развлечения и мультимедиа",
    "Security": "Безопасность",
    "Laundry And Linen": "Стирка и белье",
    "Amenities Outside": "Удобства снаружи",
    "Methods Of Payments": "Способы оплаты",
    "Show More": "Показать больше",
    "Sign Up": "Регистрация",
    "Password": "Пароль",
    "User With That Name Already Exists": "Пользователь с этим именем уже существует",
    "User With That Email Already Exists": "Пользователь с этой почтой уже существует",
"Incorrect Password Or Username": "Неверный пароль или имя пользователя",
    "Delete": "Удалить",
    "Update Apartment Data" : "Обновите информацию о квартире"
};

export type Translation = typeof russianTranslation;

