import './styles.scss';
import {authModalStore} from "../model/auth-modal-store";
import {observer} from "mobx-react";
import clsx from "clsx";
import {SvgButton} from "../../../shared/ui/svg-button";
import CrossIcon from "../../../assets/images/cross.svg";
import {useTypedTranslation} from "../../../app/i18n/use-typed-translation";
import {useState} from "react";
import {SignUpForm} from "../../../features/sign-up";
import {SignInForm} from "../../../features/sign-in";

export const AuthModal = observer(() => {
    const {t} = useTypedTranslation()
    const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

    return <div className={clsx("auth-modal", authModalStore.isOpened && "opened")}
                onClick={authModalStore.stopPropagationInModal}>
        <SvgButton className={"auth-modal-close"} icon={CrossIcon}
                   onClick={() => authModalStore.setIsOpened(false)}/>

        <div className="auth-modal-tabs">
            {[t("Sign In"), t("Sign Up")]
                .map((tabTitle, index) => <button
                    className={clsx("auth-modal-tab", index === activeTabIndex && "active")}
                    onClick={() => setActiveTabIndex(index)}>{tabTitle}</button>)
            }
        </div>
        <div className="content">
            {activeTabIndex === 0 && (
                <SignInForm onSignIn={() => authModalStore.setIsOpened(false)}/>
            )}
            {activeTabIndex === 1 && (
                <SignUpForm onSignUp={() => authModalStore.setIsOpened(false)}/>
            )}
        </div>
    </div>
})
