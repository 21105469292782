import {Translation} from "./ru";

export const englishTranslation: Translation = {
    "Website Logo": "Website Logo",
    "Featured": "Featured",
    "Sign In": "Sign In",
    "Daily rent from owners all over Belarus": "Daily rent from owners all over Belarus",
    "You can rent apartment": "On the XXX website you can rent an apartment for a day for work and leisure, rent a house and estate for a country holiday, find with us the most advantageous offers for daily rent of apartments and estates without intermediaries",
    "Gomel": "Gomel",
    "Brest": "Brest",
    "Grodno": "Grodno",
    "Pinsk": "Pinsk",
    "Minsk": "Minsk",
    "Mogilev": "Mogilev",
    "Find": "Find",
    "Apartments": "Apartments",
    "Houses": "Houses",
    "Guests": "Guests",
    "guest_zero": "{{count}} guests",
    "guest_one": "{{count}} guest",
    "guest_few": "{{count}} guests",
    "guest_many": "{{count}} guests",
    "Filters": "Filters",
    "From": "from",
    "To": "to",
    "Price": "Price",
    "Area": "Area",
    "Amenities": "Amenities",
    "Nothing Found": "Nothing found",
    "Remove Filters": "Remove filters",
    "Price Low To High": "Price low to high",
    "Price High To Low": "Price high to low",
    "Popularity Low To High": "Popularity low to high",
    "Popularity High To Low": "Popularity high to low",
    "Show Contacts": "Show contacts",
    "Rooms": "{{count}} rooms",
    "Create Apartment": "Create apartment",
    "Title": "Title",
    "Apartment Description": "Apartment description",
    "Rooms Quantity": "Rooms quantity",
    "Beds Quantity": "Beds quantity",
    "Quests Quantity": "Quests quantity",
    "Currency": "Currency",
    "Address": "Address",
    "Photos": "Photos",
    "Add": "Добавить",
    "Save": "Save",
    "Share": "Share",
    "Day": "day",
    "Leave Request": "Leave a request",
    "Book Apartment": "Book The Apartment",
    "Enter Your Name": "Enter your name",
    "Name": "Name",
    "Phone": "Phone",
    "Email": "E-mail",
    "Number Of People": "Number Of People",
    "Check-in Date": "Check-in date",
    "Check-out Date": "Check-out date",
    "Send Request": "Send a request",
    "Additional Information": "Additional information",
    "Room_zero": "Rooms",
    "Room_one": "Room",
    "Room_few": "Rooms",
    "Room_many": "Rooms",
    "Bed_zero": "Beds",
    "Bed_one": "Bed",
    "Bed_few": "Beds",
    "Bed_many": "Beds",
    "Guest_zero": "Guests",
    "Guest_one": "Guest",
    "Guest_few": "Guests",
    "Guest_many": "Guests",
    "On Map": "On the map",
    "Description": "Description",
    "Rules Of Residence": "Rules of residence",
    "Near The House": "Near the house",
    "Reviews": "Reviews",
    "House Description": "House description",
    "In The Kitchen": "In the Kitchen",
    "In The Bathroom": "In the Bathroom",
    "Entertainment And Multimedia": "Entertainment and multimedia",
    "Security": "Security",
    "Laundry And Linen": "Laundry and linen",
    "Amenities Outside": "Удобства Снаружи",
    "Methods Of Payments": "Methods Of Payments",
    "Show More": "Show more",
    "Sign Up": "Sign Up",
    "Password": "Password",
    "User With That Name Already Exists": "User with that name already exists",
    "User With That Email Already Exists": "User with that email already exists",
    "Incorrect Password Or Username": "Incorrect password or username",
    "Delete": "Delete",
    "Update Apartment Data": "Update apartment data",
};

